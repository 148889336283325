//
// user.js
// Use this to write your custom JS
//

// Select all tab buttons
const tabButtons = document.querySelectorAll(".tab-buttons .btn");

// Preload images
const imagesToPreload = [
  "assets/img/avatars/retail-avatar.png",
  "assets/img/magic-conversations/retail.jpg",
  "assets/img/avatars/airlines-avatar.png",
  "assets/img/magic-conversations/airline.jpg",
  "assets/img/avatars/hotel-avatar.png",
  "assets/img/magic-conversations/hotel.jpg",
  "assets/img/avatars/saas-avatar.png",
  "assets/img/magic-conversations/saas.jpg",
];

// Function to preload images
function preloadImages(imageArray) {
  imageArray.forEach((imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
  });
}

// Call preload function
preloadImages(imagesToPreload);

// Add event listeners to all buttons
tabButtons.forEach((button) => {
  button.addEventListener("click", () => {
    const sectionId = button.getAttribute("data-tab");

    // Remove the active class from all buttons
    tabButtons.forEach((btn) => btn.classList.remove("active"));

    // Add the active class to the clicked button
    button.classList.add("active");

    // Remove the active class from all sections
    document.querySelectorAll(".tab-content").forEach((section) => {
      section.classList.remove("active");
    });

    // Add the active class to the selected section
    const selectedSection = document.getElementById(sectionId);
    selectedSection.classList.add("active");

    // Update avatar, name, and background image based on the selected tab
    const avatar = button.getAttribute("data-avatar");
    const name = button.getAttribute("data-name");
    const bgImage = button.getAttribute("data-bg");

    // Update avatar image
    document.getElementById("avatar").src = avatar;

    // Update person name
    document.getElementById("person-name").textContent = name;

    // Update background image
    document.getElementById("bg-image").src = bgImage;

    // Refresh AOS to ensure animations work
    AOS.refresh();
  });
});
